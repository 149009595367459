// src/axios.js
import axios from 'axios'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import message from '@/components/UI/message'
import { ref } from 'vue'

// 创建 Axios 实例
const instance = axios.create({
  //   baseURL: process.env.VUE_APP_API_BASE_URL || 'http://localhost:3000',
  // baseURL: 'https://kgrados.getmogic.com/' || location.origin,
  // baseURL: location.origin + '/api',
  baseURL: location.origin,
  timeout: 0,
})
export const needloginFlag = ref(false)
// 添加请求拦截器
instance.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么
    // 例如，添加认证 token
    const token = document.cookie
      .split('; ')
      .find(row => row.startsWith('access_token='))
      .split('=')[1]
    const anonymoustToken = localStorage.getItem('token')
    if (token || anonymoustToken) {
      config.headers.token = token || anonymoustToken
    }
    return config
  },
  error => {
    // 对请求错误做些什么
    return Promise.reject(error)
  },
)

// 添加响应拦截器
instance.interceptors.response.use(
  async response => {
    // 对响应数据做些什么
    if (response.data.code === 40100) {
      await new Promise(resolve => {
        setCookie(resolve)
      })
      return instance(response.config)
    } else if (response.data.code === 41000) {
      //open google login page
      needloginFlag.value = true
      // message('error', 'please login')
      return Promise.reject(response.data)
    } else if (response.data.code !== 0) {
      message('error', 'Server Error')
      return Promise.reject(response.data)
    }
    return response.data
  },
  error => {
    // 对响应错误做些什么
    if (error.response) {
      // 服务器返回的错误响应
      switch (error.response.status) {
        case 401:
          // 未授权，重定向到登录页面
          window.location.href = '/login'
          break
        case 403:
          // 禁止访问，显示错误信息
          alert('您没有权限访问此资源')
          break
        case 500:
          // 服务器错误，显示错误信息
          alert('服务器发生错误，请稍后再试')
          break
        default:
          // 其他错误，显示错误信息
          // alert(error.response.data.message || '发生错误，请稍后再试');
          message('error', error.response.data.message || 'Server Error')
      }
    } else {
      // 网络错误或其他原因
      message('error', ' Network Error')
      // alert('网络错误，请检查您的网络连接');
    }
    return Promise.reject(error)
  },
)

export default instance

export function setCookie(next) {
  FingerprintJS.load().then(fp => {
    fp.get().then(result => {
      axios.get(`/user/fakeuser/${result.visitorId}`).then(response => {
        localStorage.token = response.headers.token || ''
        localStorage.email = ''
        //cookie由服务器设置，其他用户信息等后续实现
        console.log(response.data)
        if (next) next()
      })
    })
  })
}

export function getUserData(token, next, errorCallback) {
  axios
    .request({
      url: '/user',
      method: 'get',
      headers: {
        token: token,
      },
    })
    .then(response => {
      if (!response.data.data) {
        errorCallback()
        return
      }
      debugger
      const user = response.data.data
      localStorage.token = token
      localStorage.email = user.email
      if (next) next()
    })
}
